import { HttpClient } from '@angular/common/http';
import { resolveForwardRef, Injectable } from '@angular/core';
import { IEnvironment } from '@environments/IEnvironment';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { IEnvironmentSelection } from '@environments/IEnvironmentSelection';

// TODO: Remove cache busting after August 1st 2024. That should have given enough time for everyone to have downloaded the version with No-Cache headers.
const cacheBust = Date.now();

@Injectable()
export class EnvironmentService {
  private environmentsFolder = 'assets/environments';
  private configUrl = `${this.environmentsFolder}/config.json?${cacheBust}`;

  constructor(private readonly http: HttpClient) {}

  /**
   * Get config object and returns the response body in a given type.
   *
   * @return An `Observable` of the config object.
   */
  getEnvironment(environmentName?: string): Observable<IEnvironment> {
    const http = resolveForwardRef(this.http);

    return http.get<IEnvironmentSelection>(this.configUrl)
      .pipe(
        switchMap(env => {
          return this.getEnvAndMerge({ $parentEnv: environmentName || env.env });
        }),
        catchError(err => {
          console.error('Failed to get environment configuration from server. Using environment.prod instead.', err);
          return this.getEnvAndMerge({ $parentEnv: 'prod' });
        })
      );
  }

  private getEnvAndMerge(env: any): Observable<IEnvironment> {
    if (!env.$parentEnv) {
      return of(env || {});
    }
    return this.http.get<IEnvironment>(`${this.environmentsFolder}/environment.${env.$parentEnv}.json?${cacheBust}`)
      .pipe(
        mergeMap((parentEnv) =>
          this.getEnvAndMerge(parentEnv)
            .pipe(
              map(newEnv => Object.assign({}, newEnv, parentEnv))
            )
        ),
        catchError(err => {
          console.error('Failed to get environment from server.', err);
          return throwError(err);
        })
      )
  }
}
